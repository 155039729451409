<template>
  <div id="page">
    <HeaderBase/>
    <!-- <div class="nav">
      <ul>
        <li v-for="(item,index) in headernav" :key="index"
        @click="this.$router.push(item.path)">
          {{item.text}}
        </li>
      </ul>
      <div id="search">
        <div class="logosearch">
          <div id="logo">
            <img src="../../assets/logo2.png" alt="">
          </div>
          <div class="cut">
            <div id="sear">
              <input id="searin" type="text" placeholder="品牌/品种/产地">
              <button class="sou">搜索</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="banner">
        <div class="ban_left">
          <div><p>转售交易流程</p></div>
          <img src="../../static/imges/yushouLC.png" alt="">
        </div>
        <div class="ban_center">
          <el-carousel height="420px"
          ref="carousel"
          :interval="3000" arrow="hover">
            <el-carousel-item v-for="item in bannerImg" :key="item">
              <img :src="item.pic" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <div class="ban_right">
          <p>成交动态</p>
          <div class="right_table">
            <div id="table_head">
              <tr>
                <th v-for="(i,index) in dataList.title" :key="index">
                  {{i}}
                </th>
              </tr>
            </div>
            <vue-seamless-scroll
            :data="dataList.cont"
            :class-option="optionHover"
            class="seamless-warp">
              <div id="table_body">
                <table>
                  <tr
                  v-for="(k,index1) in dataList.cont" :key="index1">
                    <td style="width: 60px;">{{k.name}}</td>
                    <td style="flex:1;">{{k.time}}</td>
                    <td style="width: 60px;text-align: center;">{{k.turnover}}</td>
                  </tr>
                </table>
              </div>
            </vue-seamless-scroll>
          </div>
        </div> -->
        <div class="ban_left">
          <div><p>转售交易流程</p></div>
          <img src="../../static/imges/yushouLC.png" alt="">
        </div>
      </div>

      <div class="show_menu">
        <div class="menu_title">
          <resell-title></resell-title>
        </div>
        <div class="menu_body">
          <resell-body @dataToDetail="(data)=>{goVarDet(data)}"></resell-body>
        </div>
      </div>

    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import HeaderBase from '../../components/HeaderBase.vue'
import { homeAdvertiseList } from '../../api/api'

import Footer from '../index/Footer.vue'
import ResellBody from './ResellBody.vue'
import ResellTitle from './ResellTitle.vue'
import { onMounted, ref } from 'vue-demi'
// import VarietiyDetails from './VarietiyDetails.vue'

const dataList = {
  title: ['产品名称', '时间', '成交量'],
  cont: [
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' },
    { name: '1000kg', time: '2021-10-22', turnover: '500kg' }
  ]
}

export default {
  name: 'ResellMain',
  data () {
    return {
      bannerImg: ref([]),
      dataDetails: null
    }
  },
  components: { ResellTitle, ResellBody, Footer, HeaderBase },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    goVarDet (data) {
      // this.dataDetails = data
      window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部
    },
    async getPic () {
      const data = {
        types: 5
      }
      const res = await homeAdvertiseList(data)
      console.log(res)
      if (res.data.code === 200) {
        this.bannerImg = res.data.data
      }
    }
  },
  mounted () {
    this.getPic()
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部
  },
  setup (props, ctx) {
    // const router = useRouter()
    const carousel = ref(null)
    onMounted(() => {
      setTimeout(() => {
        carousel.value.setActiveItem(0)
      }, 500)
    })
    return {
      carousel,
      dataList
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .content{
    // height: 2000px;
    width: 1200px;
    margin: 0 auto;
    // background: $divbgColor;
    .banner{
      margin-top: 10px;
      height: 420px;
      display: flex;
      justify-content: center;
      .ban_left{
        background: #322D2B;
        div{
          background: url("../../static/imges/yushouLC01.png") no-repeat -10px;
          height: 85px;
          p{
            line-height: 75px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #322D2B;
            // z-index: 2;
            text-align: center;
          }
        }
        img{
          // margin-top: 20px;
          padding: 20px;
          padding-top: 0;
          height: 310px;
          width: 200px;
        }
      }
      .ban_center{
        flex: 1;
        // margin: 0 10px;
        // box-sizing: border-box;
        .el-carousel{
          height: 420px;
          .el-carousel__container{
            height: 420px;
            .el-carousel__item{
              // height: 100%;
                height: 420px;
              img{
                height: 420px;
                width: 100%;
              }
            }
          }
        }
      }
      .ban_right{
        width: 250px;
        background: #322D2B;
        box-sizing: border-box;
        // padding: 20px;
        p{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #C6824E;
          text-align: center;
          line-height: 50px;
        }
        .right_table{
          padding-top: 0;
          #table_head{
            padding-top: 15px;
            background: rgba(198, 130, 78, 0.1);
            padding: 0 20px;
            tr{
              display: flex;
              width: 100%;
              text-align: center;
              th{
                text-align: left;
                font-size: 12px;
                height: 30px;
                line-height: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                // background: rgba(198, 130, 78, 0.1);
                color: #C6824E;
              }
              th:first-child,th:last-child{
                width: 60px;
              }
              th:nth-child(2) {
                padding-left: 20px;
                flex: 1;
              }
              th:last-child{
                text-align: center;
              }
            }
          }
          .seamless-warp{
            padding: 0 20px;
            height: 300px;
            overflow: hidden;
            margin: 20px 0;
            #table_body{
              table{
                display: flex;
                flex-flow: column;
                tr{
                  height: 30px;
                  flex-shrink: 0;
                  box-sizing: border-box;
                  display: flex;
                  td{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    line-height: 30px;
                    font-weight: 400;
                    color: #C6824E;
                    // flex: 1;
                  }
                }
                tr:hover{
                  cursor: pointer;
                  background: rgba(255, 255, 255, 0.1);
                }
              }
            }
          }
        }
      }
    }
    .show_menu{
      margin-top: 10px;
      .menu_title{
        margin-top: 10px;
      }
      .menu_body{
        width: 100%;
        display: flex;
      }
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
