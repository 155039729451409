<template>
  <div id="menutitle">
    <div class="title_div">
      <span class="navspan f1">已选</span>
      <div class="spanlist">
        <el-tag v-for="(tag, item) in tags" :key="item"
          closable
          size="small"
          @close="handleClose(tag, tags)">
          {{ tag }}
        </el-tag>
      </div>
      <button
        @click="handleClose(this.tags)">重置</button>
    </div>
    <!-- <div class="title_div">
      <span class="navspan">交货周期</span>
      <ul class="huotime">
        <li v-for="(i,index) in huotimes" :key="index"
        @click="handleAddOne(i, huotimes),filtrateInquire('time', index)">
          {{i}}
        </li>
      </ul>
    </div> -->
    <div class="title_div">
      <span class="navspan">品种</span>
      <ul class="huotime">
        <li v-for="i in pinzhongs" :key="i"
        @click="handleAddMulti(i,'variety'),filtrateInquire('variety', i, pinzhongs)">
          {{i.name}}
        </li>
      </ul>
    </div>
    <!-- <div class="title_div">
      <span class="navspan">产地</span>
      <ul class="huotime">
        <li v-for="i in chandis" :key="i"
        @click="handleAddMulti(i),filtrateInquire('place', i, chandis)">
          {{i}}
        </li>
      </ul>
    </div> -->
    <!-- <div class="title_div">
      <ul class="selectul">
        <li class="selectli">
            <el-select v-model="salesvalue"
            placeholder="销量"
            @change="filtrateInquire('sale',salesvalue)"
            size="small">
            <el-option
              v-for="item in optionsxiaoliang"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li class="selectli">
            <el-select v-model="pricevalue"
            placeholder="价格"
            @change="filtrateInquire('price',pricevalue)"
            size="small">
            <el-option
              v-for="item in optionsPrice"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue-demi'
import http from '../../api/http.js'
import emitter from '../../tools/bus.js'
// import throttle from '../../tools/throttle.js'

let tags = reactive([])
const huotimes = reactive(['次月', '10天内', '30天内', '半年内'])
let pinzhongs = reactive([])
const chandis = reactive(['中国', '巴西', '伊拉克', '叙利亚', '南非'])
const optionsPrice = ref([{ value: '降序', label: '价格降序' }, { value: '升序', label: '价格升序' }])
const optionsxiaoliang = ref([{ value: '降序', label: '销量降序' }, { value: '升序', label: '销量升序' }])

// 查品种
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}
export default {
  name: 'ResellTitle',
  data () {
    return {
      salesvalue: ref(''),
      pricevalue: ref(''),
      paramsList: {
        deliveryPeriod: null, // 交货周期:0->次月；1->10天内；2->30天内；3->半年内；
        keyword: [], // 品种
        originPlace: [], // 产地
        sort: null, // 排序字段:0->按相关度；1->销量从低到高；2->销量从高到低；3->价格从低到高；4->价格从高到低
        pageSize: 16,
        pageNum: 1
      },
      keywordStr: '',
      originPlaceStr: ''
    }
  },
  methods: {
    // 删除所选项 ,或者重置已选择
    handleClose (name, tag, tagslist) {
      console.log(name)
      console.log(tag)
      console.log(tagslist)
      // console.log(name, tag, tagslist)
      if (typeof name !== 'string') {
        this.tags.splice(0, name.length)
        this.keywordStr = null
      } else {
        this.tags.splice(tag.indexOf(name), 1)

        this.paramsList.deliveryPeriod = null

        pinzhongs.forEach(item => {
          // console.log(item)
          if (name === item.name) {
            this.paramsList.keyword.splice(this.paramsList.keyword.indexOf(item.id), 1)
            this.keywordStr = this.arrayToString(this.paramsList.keyword)
            return true
          }
        })

        // if (this.paramsList.keyword.indexOf(name) !== -1) {
        //   this.paramsList.keyword.splice(this.paramsList.keyword.indexOf(name), 1)
        //   this.keywordStr = this.arrayToString(this.paramsList.keyword)
        //   // console.log(this.keywordStr)
        // } else {
        //   this.paramsList.originPlace.splice(this.paramsList.originPlace.indexOf(name), 1)
        //   this.originPlaceStr = this.arrayToString(this.paramsList.originPlace)
        //   // console.log(this.originPlaceStr)
        // }
      }
      this.toSend(
        {
          deliveryPeriod: this.paramsList.deliveryPeriod,
          sort: this.paramsList.sort,
          specialSubjectId: this.keywordStr,
          originPlace: this.originPlaceStr,
          pageSize: 16,
          pageNum: 1,
          previewStatus: 1
        }
      )
    },
    handleAddOne (tag, tagslist) { // 单选添加
      // console.log(tag)
      for (let i = 0; i < tagslist.length; i++) {
        // console.log(tagslist[i])
        for (let j = 0; j < this.tags.length; j++) {
          if (tagslist[i] === this.tags[j]) {
            this.tags[j] = tag
            return
          }
        }
      }
      this.tags.push(tag)
    },
    handleAddMulti (tag, name) { // 多选 添加
      for (let j = 0; j < this.tags.length; j++) {
        if (name === 'variety') {
          if (tag.name === this.tags[j]) {
            return
          }
        } else {
          if (tag === this.tags[j]) {
            return
          }
        }
      }
      if (name === 'variety') {
        this.tags.push(tag.name)
      } else {
        this.tags.push(tag)
      }
    },
    /* item:当前选择的查询字段
     * name:判断是什么类型的查询
     * tagslist:当前所有类型
     */
    filtrateInquire (name, item, tagslist) { // 筛选 查询  方法
      // const all = this.tags
      // console.log(name, item)
      switch (name) {
        case 'time':
          this.paramsList.deliveryPeriod = item
          break
        case 'variety':
          this.paramsList.keyword.push(item.id)
          this.keywordStr = this.arrayToString(this.paramsList.keyword)
          // console.log(this.keywordStr)
          break
        case 'place':
          this.paramsList.originPlace.push(item)
          this.originPlaceStr = this.arrayToString(this.paramsList.originPlace)
          // console.log(this.originPlaceStr)
          break
        case 'sale':
          this.pricevalue = ''
          if (item === '升序') {
            this.paramsList.sort = 1
          } else {
            this.paramsList.sort = 2
          }
          break
        case 'price':
          this.salesvalue = ''
          if (item === '升序') {
            this.paramsList.sort = 3
          } else {
            this.paramsList.sort = 4
          }
          break
        default:
          break
      }
      // console.log(toRaw(this.paramsList))
      this.paramsList = toRaw(this.paramsList)
      // console.log(throttle)
      this.toSend(
        {
          deliveryPeriod: this.paramsList.deliveryPeriod,
          sort: this.paramsList.sort,
          specialSubjectId: this.keywordStr,
          originPlace: this.originPlaceStr,
          pageSize: 16,
          pageNum: this.paramsList.pageNum || 1
        })
      // http.get('/mall-portal/product/search', this.paramsList)
      //   .then(res => {
      //     console.log(res)
      //   }).catch(err => {
      //     console.log(err)
      //   })
    },
    toSend (data) { // 请求 数据
      // console.log(data)
      http.get('/mall-order/resale/search', data)
        .then(res => {
          console.log(res)
          emitter.emit('resellList', res.data.data)
        }).catch(err => {
          console.log(err)
        })
    },
    arrayToString (selectTagslist) {
      // console.log(paramsList)
      const temp = []
      for (let j = 0; j < selectTagslist.length; j++) {
        if (temp.indexOf(selectTagslist[j]) === -1) {
          temp.push(selectTagslist[j])
        }
      }
      // console.log(temp)
      return temp.toString()
    }
  },
  created () {
    emitter.on('toPageNum', (data) => {
      this.paramsList.pageNum = data
      console.log(this.paramsList.pageNum)
      this.toSend({
        deliveryPeriod: this.paramsList.deliveryPeriod,
        sort: this.paramsList.sort,
        keyword: this.keywordStr,
        originPlace: this.originPlaceStr,
        pageSize: 16,
        pageNum: this.paramsList.pageNum,
        previewStatus: 1
      })
    })
    // this.handleClose()
    http.get('/mall-order/resale/search', { pageSize: 16, pageNum: this.paramsList.pageNum, previewStatus: 1 })
      .then(res => {
        // console.log(res.data.data)
        emitter.emit('resellList', res.data.data)
      }).catch(err => {
        console.log(err)
      })
  },
  setup () {
    // 查品种
    pinzhongs = reactive([])
    tags = reactive([])
    const getVarietyList = async () => {
      const res = await topicList()
      console.log(res)
      if (res.data.code === 200) {
        res.data.data.forEach(item => {
          pinzhongs.push({
            name: item.name,
            id: item.id
          })
        })
        console.log(pinzhongs)
        // console.log(...pinzhongs)
      }
    }
    onMounted(() => {
      getVarietyList()
      // getChanDiList()
    })
    return {
      tags,
      huotimes,
      pinzhongs,
      chandis,
      optionsPrice,
      optionsxiaoliang,
      getVarietyList
    }
  }
}
</script>

<style lang="scss" >
#menutitle{
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  .title_div{
    display: flex;
    height: 49px;
    justify-content: right;
    align-items: center;
    border-bottom: 1px solid #F2EFED;
    .selectul{
      display: flex;
      width: 220px;
      justify-content: space-between;
      .selectli{
        width: 100px;
      }
    }
    .navspan{
      width: 100px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color:#333333;
    }
    .f1{
      color: #C6824E;
    }
    .huotime{
      flex: 1;
      display: flex;
      li{
        margin-right: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
    }
    .spanlist{
      flex: 1;
      .el-tag{
        margin-right: 10px;
        border-color: #C6824E;
        color: #C6824E;
        .el-icon{
          color: #C6824E;
        }
        .el-icon:hover{
          color: #ffffff;
          --el-tag-hover-color: #8b553b;
        }
      }
    }
    button{
      width: 60px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #EAEAEA;
      border-radius: 4px;
    }
  }
  .title_div:last-child{
    border: 0;
    // height: 32px;
  }
}
</style>
